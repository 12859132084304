<template>
  <div class="box">
    <div class="headline">人员管理</div>
    <div class="content">
      <p>进入人员管理界面，【系统设置】→【人员管理】，如下图</p>
      <img src="../../assets/specificationImgs/img16.png" alt width="100%" />
      <p>页面打开默认显示所有人员，选择“公司”，“部门”，可筛选人员，下方显示的是人员的详细信息。</p>
      <p>
        1）新增：点击添加人员，选择《所属公司》、《所属部门》等按需填岗位信息，然后点击点击“确定添加”即可。 <br />
        2）修改：从列表选中岗位，点击“修改”，按需修改岗位信息，点击“确定”。 <br />
        3）删除：从列表选中岗位，点击“红色垃圾桶”。
      </p>
      <p>
        操作技巧：<br />
        1）“新增”或“修改”后，不需要保存，点击“取消”。
        <img src="../../assets/specificationImgs/img17.png" alt width="100%" />
        2）绑定角色需要配合角色权限管理操作配合使用。所需要的角色信息需要在3.7操作中新增。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
